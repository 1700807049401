import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { LanguageEntry } from "shared-states";

@Injectable({ providedIn: 'root' })
export class SupportedLanguageService {

  constructor(private _httpClient: HttpClient) {
  }

  getSupportedLanguage(uri: string): Promise<LanguageEntry[]> {
    return lastValueFrom(this._httpClient.get<LanguageEntry[]>(uri));
  }
}
