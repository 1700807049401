import { ThemeType } from "../../models/enums/theme.enum";
import { MenuEntry } from "../../../modules/protected/components/menu/models/menu.model";
import { TecComModuleType } from "shared-states";

export namespace Shell {
  export class InitShellSuccessAction {
    static readonly type = "[Init Shell] Shell init";

    constructor(public theme: ThemeType, public menu: MenuEntry[], public userMenu: MenuEntry[], public error?: Error) {
    }
  }

  export class UpdateUserMenuSuccessAction {
    static readonly type = "[User Menu] User menu updated";

    constructor(public menu: MenuEntry[]) {
    }
  }

  export class ChangeThemeSuccessAction {
    static readonly type = "[Change Theme] App theme changed";

    constructor(public theme: ThemeType) {
    }
  }

  export class ChangeActiveModuleSuccessAction {
    static readonly type = "[Change Active Module] Active module changed";

    constructor(public module: TecComModuleType | undefined) {
    }
  }
}
