import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ShellState } from '../states';
import { Observable, of } from 'rxjs';
import { PROTECTED_REMOTES_ROUTES } from 'src/app/routes/protected.routes';

@Injectable({ providedIn: 'root' })
export class UserPermissionGuard implements CanActivate {

  constructor(private _store: Store) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._store.select(ShellState.isInitialized).pipe(
      filter((isInitialized: boolean) => isInitialized),
      switchMap(() => {
        // Allow access to unauthorized and error pages
        if (state.url === '/unauthorized' || state.url === '/error' || state.url.includes('/error')) {
          return of(true);
        }
        // Check if active module is accessible
        const activeModule = PROTECTED_REMOTES_ROUTES.find((r) => r.routePath === state.url.split('/')[1]);
        if (activeModule && activeModule.isAccessible) {
          // Active module is accessible, check user permission
          return this._store.select(ShellState.hasPathPermission(state.url)).pipe(
            map(hasPermission => {
              if (!hasPermission) {
                // User doesn't have permission
                this._store.dispatch(new Navigate(['/unauthorized']));
                return false;
              }
              return true;
            }));
        } else {
          // Active module is not found or is not accessible
          this._store.dispatch(new Navigate(['/error']));
          return of(false);
        }
      })
    );
  }
}