import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, filter, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState, UserStateModel } from 'shared-states';
import { environment } from 'src/environments/environment';

@Injectable()
export class InternalTokenInterceptor implements HttpInterceptor {

  constructor(private _store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.isFullyUrl(request.url) ? this.parseUrl(request.url) : null;
    
    if(url && environment.allowedDomains.includes(url) && !environment.excludedDomains.includes(url)) {
      return this._store.select(UserState.isAuthenticated).pipe(
        filter((isAuthenticated: boolean) => isAuthenticated),
        switchMap(() => {
          const token = (<any>this._store.selectSnapshot<UserStateModel>(UserState).user)!.internalToken;
          const headers = request.headers.set('Token', token);
          const modified = request.clone({
            url: request.url,
            headers: headers,
            body: request.body
          });
          return next.handle(modified);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private isFullyUrl(url: string): boolean {
    return url.includes("https://") || url.includes("http://")
  }

  private parseUrl(url: string) {
    const newUrl = new URL(url);
    return `${newUrl.hostname}${newUrl.port ? `:${newUrl.port}` : ''}`;
  }
}