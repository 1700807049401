import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Select } from "@ngxs/store";
import { DeviceType, UserState } from "shared-states";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CookieStorageService } from '../../services/cookie-storage.service';

@Component({
  selector: 'app-cookie-settings-overview',
  templateUrl: './cookie-settings-overview.component.html'
})
export class CookieSettingsOverviewComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<CookieSettingsOverviewComponent>,
    private _storageService: CookieStorageService
  ) { }

  setCookieConsent(value: string) {
    this._storageService.set('cookieconsent_status', value);
    this._bottomSheetRef.dismiss(value);
  }
}
