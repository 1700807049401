import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { from, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OktaSessionGuard implements CanActivate {
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const sessionExists = await this.oktaAuth.session.exists();
    if (!sessionExists) {
      this.oktaAuth.clearStorage();
      return false;
    }
    return true;
  }
}
