import { Injectable } from '@angular/core';
import { UserState, UserStateModel } from "shared-states";
import { environment } from "../../../../environments/environment";
import { Store } from "@ngxs/store";
import { CookieSettingsOverviewComponent } from "../cookie-settings-overview/cookie-settings-overview.component";
import { first } from "rxjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { CookieStorageService } from "../../services/cookie-storage.service";

@Injectable()
export class FooterService {
  public readonly COOKIE_STATUS_KEY = 'cookieconsent_status';

  private _bottomSheetRef: any;

  constructor(
    private _store: Store,
    private _storageService: CookieStorageService,
    private _bottomSheet: MatBottomSheet,) { }

  openCookieSettings() {
    this._bottomSheetRef = this._bottomSheet.open(CookieSettingsOverviewComponent, {
      panelClass: 'cookie-settings',
      hasBackdrop: false
    });

    this._bottomSheetRef?.afterDismissed()
      .pipe(first())
      .subscribe((value: any) => this._storageService.set(this.COOKIE_STATUS_KEY, value));
  }

  contactSupport() {
    const userStateModel = this._store.selectSnapshot<UserStateModel>(UserState);
    let uri = environment.supportPageUri;
    if (userStateModel.token) {
      uri += this.createHtmlQueryParams(userStateModel);
    }
    window.open(uri, '_blank');
  }

  private createHtmlQueryParams(userState: UserStateModel): string {
    const queryParams = [
      `firstname=${encodeURIComponent(userState.user?.firstName || '')}`,
      `lastname=${encodeURIComponent(userState.user?.lastName || '')}`,
      `email=${encodeURIComponent(userState.user?.email || '')}`,
      `language=${encodeURIComponent(userState.user?.language || '')}`,
      `orgtecid=${encodeURIComponent(userState.selectedOrganization?.tecComId || '')}`,
      `orgname=${encodeURIComponent(userState.selectedOrganization?.name || '')}`,
      `product=${encodeURIComponent('omp')}`,
      `brand=${encodeURIComponent(1)}`
    ];

    return '?' + queryParams.join('&');
  }
}
