import { environment } from '../environments/environment';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export const oktaConfig: OktaAuthOptions = {
  clientId: environment.oktaOptions.clientId,
  issuer: environment.oktaOptions.issuerUri,
  redirectUri: environment.oktaOptions.redirectUri,
  postLogoutRedirectUri: environment.oktaOptions.postLogoutRedirectUri,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  tokenManager: {
    autoRenew: true
  },
  ignoreLifetime: true
};