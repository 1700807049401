import { Injectable } from '@angular/core';
import { MenuEntry } from '../models/menu.model';
import { PROTECTED_REMOTES_ROUTES } from 'src/app/routes/protected.routes';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, take, toArray } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { RemoteMenuType } from 'src/app/core/models/enums/remote-menu.enum';
import { isEmpty } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(
    private _httpClient: HttpClient,
    private _store: Store,
    private _router: Router,) { }

  private virtualMenuEntries: MenuEntry[] = [];

  navigateTo(entry: MenuEntry) {
    // TODO Remove logic when migration is done
    if (entry.legacyModule) {
      // Route to legacy solution
      window.location.href = `https://${entry.legacyModule}${environment.legacyDomainSuffix}${this.removeModulePrefix(entry.path)}`;
    } else {
      this._store.dispatch(new Navigate([entry.path]));
    }
  }

  isPathActive(path: string): boolean {
    return this._router.url.startsWith(`/${path}`);
  }

  /**
   * Initializes the navigation menu based on microfrontend configurations.
   * @returns An observable emitting an array of menu entries.
   */
  init(): Observable<MenuEntry[]> {
    return from(PROTECTED_REMOTES_ROUTES).pipe(
      filter((route) => route.menuType !== RemoteMenuType.None),
      concatMap((route) => {
        const menuEntry = <MenuEntry>{
          label: route.displayLabel ? `Shell.Menu.${route.displayName}.Main` : '',
          icon: route.displayIcon,
          path: route.routePath,
          isBeta: route.isBeta,
          module: route.module,
          entries: [],
          legacyModule: route.legacyModule
        };
        // Return default menu or add remote sub entries to main route
        return route.menuType === RemoteMenuType.Default ? of(menuEntry) :
          this.getJsonMenu(`${route.remoteUri}${route.menuJsonPath}`).pipe(
            map((remoteEntries) => {
              menuEntry.entries = remoteEntries?.map((entry: MenuEntry) => {
                // Prefix remote child entries path with route remote main path
                entry.path = `${route.routePath}/${entry.path}`;
                // Prefix remote child entries label with route remote main displayName (i18n)
                entry.label = `${route.displayName}.${entry.label}`;
                if(environment.production && entry.isBeta)
                {
                  return <MenuEntry>{};
                }
                if (entry.module) {
                  // Virtual entry, will be added to the specified module
                  this.virtualMenuEntries.push(entry);
                  return <MenuEntry>{};
                }
                return entry;
              }).filter(entry => !isEmpty(entry));

              return environment.production && menuEntry.isBeta ? <MenuEntry>{} : menuEntry;
            })
          );
      }),
      toArray(),
      mergeMap((menuEntries) => {
        // Add the virtual menu entries to the specified module
        this.virtualMenuEntries.forEach((entryToAdd) => {
          menuEntries.find((mainEntry) => mainEntry.module === entryToAdd.module)?.entries.push(entryToAdd);
        });
        this.virtualMenuEntries = [];
        return menuEntries;
      }),
      toArray()
    )
  }

  /**
   * Retrieves menu entries from a remote JSON source.
   * @param uri - The URL of the JSON menu.
   * @returns An observable emitting an array of menu entries.
   */
  private getJsonMenu(uri: string): Observable<MenuEntry[]> {
    return this._httpClient.get<MenuEntry>(uri).pipe(take(1),
      map((menu) => menu?.entries),
      catchError(() => of([]))
    );
  }

  private removeModulePrefix(path: string): string {
    const segments = path.split('/');
    segments.shift();
    return `/${segments.join('/')}`;
  }
}
