import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ShellService } from './services/shell.service';
import { OKTA_AUTH, OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { oktaConfig } from '../okta.config';
import { TecComUserService } from './services/teccom-user.service';
import { environment } from 'src/environments/environment';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { LanguageService, SharedStatesModule } from 'shared-states';
import { SupportedLanguageService } from './services/supported-language.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationsLoader } from './loaders/translations-loader';
import { PUBLIC_REMOTES_ROUTES } from '../routes/public.routes';
import { PROTECTED_REMOTES_ROUTES } from '../routes/protected.routes';
import { Microfrontend } from './models/types/microfrontend.types';
import { CUSTOMER_REMOTES_ROUTES } from '../routes/customer.routes';
import { NotificationService } from './services/notification.service';
import { CookieStorageService } from '../shared/services/cookie-storage.service';
import { RemoteTranslations } from './models/remote-translations.model';
import { InternalTokenInterceptor } from './interceptors/internal-token.interceptor';
import { OrganizationService } from './services/organization.service';

const oktaAuth = new OktaAuth(oktaConfig);

export function createTranslateLoader(http: HttpClient) {
  const remoteTranslation = [...PROTECTED_REMOTES_ROUTES, ...PUBLIC_REMOTES_ROUTES, ...CUSTOMER_REMOTES_ROUTES].map((route: Microfrontend) => {
    return <RemoteTranslations>{
      keyPrefix: route.displayName,
      uri: route.translationScope ? undefined : `${route.remoteUri}${route.i18nPath}`,
      scope: route.translationScope
    }
  });
  return new TranslationsLoader(http, remoteTranslation);
}

export function languageInitializerFactory(languageService: LanguageService, supportedLanguageService: SupportedLanguageService, storageService: CookieStorageService) {
  return () => supportedLanguageService.getSupportedLanguage('assets/i18n/languages.json')
    .then((languages: any) => {
      languageService.init(languages.languages, 'en');
      if (storageService.get('Language') && storageService.get('Language') !== 'en') {
        languageService.use(storageService.get('Language'));
      }
    });
}

export function jwtOptionsFactory(oktaAuth: OktaAuth) {
  return {
    tokenGetter: () => {
      return oktaAuth.getAccessToken();
    },
    allowedDomains: environment.allowedDomains
  };
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [OKTA_AUTH]
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedStatesModule
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    TranslateModule,
    SharedStatesModule
  ],
  providers: [
    ShellService,
    TecComUserService,
    TranslateService,
    OrganizationService,
    LanguageService,    
    SupportedLanguageService,
    CookieStorageService,
    NotificationService,
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: languageInitializerFactory,
      deps: [LanguageService, SupportedLanguageService, CookieStorageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: InternalTokenInterceptor, multi: true
    }
  ],

})
export class ShellCoreModule {
  static forRoot(): ModuleWithProviders<ShellCoreModule> {
    return {
      ngModule: ShellCoreModule
    };
  }
}
