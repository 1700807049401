import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './routes/app.routes';
import { ShellSharedModule } from './shared/shell-shared.module';
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ShellState } from './core/states';
import { ShellCoreModule } from './core/shell-core.module';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { FooterComponent } from './shared/components/footer/footer.component';
import { FooterService } from "./shared/components/services/footer.service";
import { CacheManagerService } from './core/services/remote-preload.service';

export function preloadRemoteModule(remotePreloadingService: CacheManagerService) {
  return () => remotePreloadingService.cacheRemoteEntries();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    ShellCoreModule.forRoot(),
    ShellSharedModule.forChild(),
    NgxsModule.forRoot([ShellState]),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    FooterService,
    {
        provide: APP_INITIALIZER,
        useFactory: preloadRemoteModule,
        deps: [CacheManagerService],
        multi: true
    }
  ],
  exports: [
    FooterComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
