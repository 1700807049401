import { environment } from 'src/environments/environment';
import { Microfrontend } from '../core/models/types/microfrontend.types';
import { RemoteMenuType } from '../core/models/enums/remote-menu.enum';

export const CUSTOMER_REMOTES_ROUTES: Microfrontend[] = [
  {
    ...environment.microfrontends.returns,
    exposedModule: environment.microfrontends.returns.exposedModule[1],
    displayName: 'ReturnsPublic',
    displayLabel: false,
    routePath: 'returns-public',
    ngModuleName: 'ReturnsPublicModule',
    menuType: RemoteMenuType.None,
    i18nPath: 'assets/returns/i18n/'
  }
]
