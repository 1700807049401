import { Inject, Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { Observable, combineLatest, from, of } from 'rxjs';
import { User, Organization, TecComUser } from 'shared-states';
import { TecComUserService } from './teccom-user.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { MenuService } from 'src/app/modules/protected/components/menu/services/menu.service';
import { CookieStorageService } from 'src/app/shared/services/cookie-storage.service';
import { Shell, ShellState, ShellStateModel } from '../states';
import { MenuEntry } from 'src/app/modules/protected/components/menu/models/menu.model';
import { ThemeType } from '../models/enums/theme.enum';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { NotificationService } from './notification.service';
import { UserPermissionGuard } from '../guards/user-permission.guard';
import { OrganizationService } from './organization.service';

@Injectable({ providedIn: 'root' })
export class ShellService {
  private readonly SELECTED_ORG_KEY = 'selectedOrgTecId';

  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _oktaAuthStateService: OktaAuthStateService,
    private _tecComUserService: TecComUserService,
    private _menuService: MenuService,
    private _storageService: CookieStorageService,
    private _notificationService: NotificationService,
    private _organizationService: OrganizationService,
    private _store: Store,
    private _injector: Injector,
    private _router: Router) {
  }

  initUser(): Observable<boolean> {
    return this._oktaAuthStateService.authState$.pipe(
      switchMap((authState: AuthState) => {
        const oktaToken = <any>authState?.accessToken;
        const user$ = this._tecComUserService.getTecComUser().pipe(catchError(() => of(null)));
        const notification$ = this._notificationService.init().pipe(catchError(() => of([])));
        return combineLatest([user$, notification$]).pipe(
          take(1),
          map(([tecComUser]) => {
            if (!tecComUser) {
              this._store.dispatch(new Shell.InitShellSuccessAction(ThemeType.light, [], [], new Error('Shell.Common.ErrorPage.UserServiceUnavailable')));
              this._store.dispatch(new User.UserLoginSuccessAction(<TecComUser>{}, oktaToken));
              return false;
            }
            // Dispatch user login success
            this._store.dispatch(new User.UserLoginSuccessAction(tecComUser, oktaToken));
            return true;
          })
        );
      })
    );
  }

  initOrganizations(): Observable<boolean> {
    // Get organizations
    const organizations$ = this._organizationService.getOrganizations().pipe(catchError(() => of(null)));
    // Get menu
    const menu$ = this._menuService.init()
    return combineLatest([organizations$, menu$]).pipe(
      take(1),
      map(([organizations, menu]) => {
        if (!organizations) {
          this._store.dispatch(new Shell.InitShellSuccessAction(ThemeType.light, [], [], new Error('Shell.Common.ErrorPage.OrganizationServiceUnavailable')));
          return false;
        }
        // Selected organization
        const selectedOrgId = this._storageService.get(this.SELECTED_ORG_KEY);
        const selectedOrg = organizations.find((org: Organization) => org.tecComId === selectedOrgId) ?? organizations.find(() => true)!;
        // Set User Menu
        const currentMenu = this.updateUserMenu(menu, selectedOrg);
        // Theme
        this._store.dispatch(new User.OrganizationInitSuccessAction(organizations, selectedOrg));
        this._store.dispatch(new Shell.InitShellSuccessAction(ThemeType.light, menu, currentMenu));
        return true;
      })
    );
  }

  selectedOrganizationChange(organization: Organization): Observable<boolean> {
    return this._store.select(ShellState).pipe(
      take(1),
      switchMap((s: ShellStateModel) => {
        const currentMenu = this.updateUserMenu(s.defaultMenu, organization);
        this._store.dispatch(new User.OrganizationChangeSuccessAction(organization));
        this._store.dispatch(new Shell.UpdateUserMenuSuccessAction(currentMenu));
        this._storageService.set(this.SELECTED_ORG_KEY, organization.tecComId);
        const userPermissionGuard = this._injector.get(UserPermissionGuard);
        return userPermissionGuard.canActivate(this._router.routerState.snapshot.root, this._router.routerState.snapshot);
      })
    );
  }

  login(): Observable<void> {
    return from(this._oktaAuth.signInWithRedirect())
  }

  logout(): Observable<boolean> {
    return from(this._oktaAuth.signOut());
  }

  private updateUserMenu(menu: MenuEntry[], selectedOrg: Organization | undefined) {
    return cloneDeep(menu).filter((m: MenuEntry) => {
      // Filter main entries with module authorization
      return selectedOrg?.permissions.modules.includes(m.module!);
    }).map((entry: MenuEntry) => {
      // Filter sub entries with permissions
      if (entry.entries && entry.entries.length > 0) {
        const userPermission = selectedOrg?.permissions.legacyPermissions ?? [];
        entry.entries = entry.entries.filter((subEntry: MenuEntry) => {
            if (!subEntry.permissions || subEntry.permissions?.length === 0) {
              // If subentry doesn't have any explicit permission, then we return true (allow access)
              return true;
            } else {
              // check the subentry path permissions
              return subEntry.permissions?.some((permission) => userPermission.includes(permission)) ?? false
            }
          }
        );
      }
      return entry;
    });
  }
}