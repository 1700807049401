import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TscDialogModel } from './models/tsc-dialog.model';

@Component({
  selector: 'tsc-dialog',
  templateUrl: './tsc-dialog.component.html'
})
export class TscDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<TscDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public model: TscDialogModel
  ) { }

  dismiss(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
