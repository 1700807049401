import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedStatesModule } from 'shared-states';
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./components/footer/footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { CookieStorageService } from "./services/cookie-storage.service";
import { TscBetaDirective } from "./directives/tsc-beta.directive";
import { CookieSettingsOverviewComponent } from './components/cookie-settings-overview/cookie-settings-overview.component';
import { SharedComponentsModule } from "shared-components";
import { TscDialogComponent } from './components/tsc-dialog/tsc-dialog.component';

@NgModule({
  declarations: [
    FooterComponent,
    CookieSettingsOverviewComponent,
    TscBetaDirective,
    TscDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedStatesModule,
    TranslateModule,
    SharedComponentsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    SharedStatesModule,
    TranslateModule,
    SharedComponentsModule,
    FooterComponent,
    TscBetaDirective,
    TscDialogComponent
  ],
  providers: [
    CookieStorageService
  ]
})
export class ShellSharedModule {
  static forChild(): ModuleWithProviders<ShellSharedModule> {
    return {
      ngModule: ShellSharedModule
    };
  }
}

