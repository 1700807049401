<div class="tsc-dialog">
  <div class="tsc-dialog__header">
    <div class="tsc-dialog__header__icon">
      <mat-icon fontSet="ta-icons" [fontIcon]="model.icon"></mat-icon>
    </div>
    <div class="tsc-dialog__header__title">
      <span class="typo-ui-h-3">{{ model.title | translate }}</span>
      <span class="typo-ui-text-sm-regular">{{ model.subtitle | translate }}</span>
    </div>
    <mat-icon *ngIf="model.dismissable" class="tsc-dialog__header__close" fontSet="ta-icons" fontIcon="x" (click)="dismiss()"></mat-icon>
  </div>
  <mat-dialog-content class="tsc-dialog__content">
    <span class="typo-ui-text-md-regular">{{ model.description | translate }}</span>
  </mat-dialog-content>
  <mat-dialog-actions class="tsc-dialog__actions">
    <button *ngIf="model.dismissable" mat-stroked-button (click)="dismiss()" color="accent">{{ model.dismissLabel | translate }}</button>
    <button mat-flat-button (click)="confirm()" color="primary">{{ model.okLabel | translate }}</button>
  </mat-dialog-actions>
</div>