<h4>{{ 'Shell.Footer.CookieSettingsOverview.Title' | translate }}</h4>
<mat-divider></mat-divider>
<div class="flex cookie-container">
  <div class="flex cookie-description">
    {{'Shell.Footer.CookieSettingsOverview.Message'|translate}}
    <hr>
    <p>{{'Shell.Footer.CookieSettingsOverview.Message_P1'|translate}}</p>
    <p>{{'Shell.Footer.CookieSettingsOverview.Message_P2'|translate}}</p>
    <!-- GDPR AGREEMENTS -->
    <mat-expansion-panel (closed)="true" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'Shell.Footer.CookieSettingsOverview.GDPR_Disclaimer_Title'|translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p><span [innerHTML]="'Shell.Footer.CookieSettingsOverview.GDPR_Disclaimer_Content' | translate"></span>
        <a [href]="'Shell.Footer.CookieSettingsOverview.LGPDUrl' | translate"
          target="_blank">{{'Shell.Footer.CookieSettingsOverview.DownloadFile'|translate}}</a>
      </p>
    </mat-expansion-panel>
    <!-- SERVICE AGREEMENTS -->
    <mat-expansion-panel (closed)="true" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'Shell.Footer.CookieSettingsOverview.ServicesInformation'|translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <b>{{'Shell.Footer.CookieSettingsOverview.Application_Insights'|translate}}</b>
      <ul>
        <li>{{'Shell.Footer.CookieSettingsOverview.AppInsights_Purpose'|translate}}</li>
        <li>{{'Shell.Footer.CookieSettingsOverview.AppInsights_Use'|translate}}</li>
        <li>{{'Shell.Footer.CookieSettingsOverview.AppInsights_Provider'|translate}}</li>
        <li>{{'Shell.Footer.CookieSettingsOverview.PrivacyPolicy'|translate}}:
          <a [href]="'Shell.Footer.CookieSettingsOverview.AppInsight_PrivacyUrl' | translate"
            target="_blank">{{'Shell.Footer.CookieSettingsOverview.AppInsight_PrivacyUrl' | translate}}</a>
        </li>
        <li>{{'Shell.Footer.CookieSettingsOverview.AppInsights_USDataProcessing'|translate}}</li>
      </ul>
      <!-- hotjar -->
      <b>{{'Shell.Footer.CookieSettingsOverview.Hotjar'|translate}}</b>
      <ul>
        <li>{{'Shell.Footer.CookieSettingsOverview.Hotjar_Purpose'|translate}}</li>
        <li>
          {{'Shell.Footer.CookieSettingsOverview.Hotjar_OnlineDocumentation'|translate}} <a
            [href]="'Shell.Footer.CookieSettingsOverview.Hotjar_OnlineDocumentationUrl'|translate"
            target="_blank">{{'Shell.Footer.CookieSettingsOverview.OnlineDocumentation'|translate}}</a>
        </li>
        <li><a [href]="'Shell.Footer.CookieSettingsOverview.Hotjar_PrivacyPolicyUrl'|translate"
            target="_blank">{{'Shell.Footer.CookieSettingsOverview.Hotjar_PrivacyPolicy'|translate}}</a></li>
      </ul>
    </mat-expansion-panel>
    <!-- PRIVACY AGREEMENTS -->
    <a class="ta-policy" [href]="'Shell.Footer.CookieSettingsOverview.PrivacyPolicyUrl'|translate"
      target="_blank">{{'Shell.Footer.CookieSettingsOverview.PrivacyPolicy'|translate}}</a>
  </div>
  <div class="flex cookie-buttons">
    <button mat-flat-button color="primary" (click)="setCookieConsent('allow')">
      {{'Shell.Footer.CookieSettingsOverview.Allow'|translate}}
    </button>
    <button mat-stroked-button color="accent" (click)="setCookieConsent('deny')">
      {{'Shell.Footer.CookieSettingsOverview.Deny'|translate}}
    </button>
  </div>
</div>