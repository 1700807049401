import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Shell, ShellState, ShellStateModel } from './core/states';
import { ThemeType } from './core/models/enums/theme.enum';
import { DeviceType, ScreenSizeType, User, UserState } from 'shared-states';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { ShellService, } from './core/services/shell.service';
import { MenuEntry } from './modules/protected/components/menu/models/menu.model';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(ShellState.getTheme) theme$!: Observable<ThemeType>;
  @Select(UserState.getDeviceType) deviceType$!: Observable<DeviceType>;

  isLoadingRemote: Boolean = true;
  DeviceType = DeviceType;

  private _unsubscribe = new Subject<void>();

  constructor(private _oktaAuthStateService: OktaAuthStateService,
    private _shellService: ShellService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _store: Store,
    private _breakpointObserver: BreakpointObserver,
    private _deviceDetectorService: DeviceDetectorService,
    private _router: Router) {
    this.registerSvgLogos();
  }

  ngOnInit() {
    // Init device type
    this._store.dispatch(new User.InitDeviceSuccessAction(<DeviceType>this._deviceDetectorService.deviceType));
    
    // Init shell after user login
    this._oktaAuthStateService.authState$.pipe(
      takeUntil(this._unsubscribe),
      filter((authState: AuthState) => authState.isAuthenticated ?? false),
      // Init user and organizations
      switchMap(() => this._shellService.initUser()
        .pipe(switchMap((isUserInited) => isUserInited ? this._shellService.initOrganizations() : of(false)))
      ),
    ).subscribe((resp) => resp ? null : this._store.dispatch(new Navigate(['/error'])));

    // Handle routing events
    this._router.events.pipe(
      takeUntil(this._unsubscribe),
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      this.isLoadingRemote = true;
    });

    this._router.events.pipe(
      takeUntil(this._unsubscribe),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.isLoadingRemote = false;
      // Set new active module when Route is navigated
      const shellState = this._store.selectSnapshot<ShellStateModel>(ShellState);
      const activeModule = shellState.userMenu.find((menu: MenuEntry) => `/${menu.path}` === event.url ||
        menu.entries.find((entry: MenuEntry) => event.url.includes( `/${entry.path}`)));
      this._store.dispatch(new Shell.ChangeActiveModuleSuccessAction(activeModule?.module));
    });

    // Handle breakpoint observer
    this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        if (result.matches) {
          const breakpoint = this.getBreakpoint(result);
          // dispatch event
          this._store.dispatch(new User.ScreenSizeChangeSuccessAction(breakpoint));
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private getBreakpoint(result: any): ScreenSizeType {
    if (result.breakpoints[Breakpoints.XSmall]) {
      return ScreenSizeType.XSmall;
    } else if (result.breakpoints[Breakpoints.Small]) {
      return ScreenSizeType.Small;
    } else if (result.breakpoints[Breakpoints.Medium]) {
      return ScreenSizeType.Medium;
    } else if (result.breakpoints[Breakpoints.Large]) {
      return ScreenSizeType.Large;
    } else if (result.breakpoints[Breakpoints.XLarge]) {
      return ScreenSizeType.XLarge;
    }
    return ScreenSizeType.Medium;
  }

  private registerSvgLogos() {
    const logoPath = '../assets/images/logos/';
    const logoList = [
      // shared component library logos
      'tecrmi-logo', 'tecalliance-logo', 'teccom-logo', 'tecdoc-logo', 'tecfleet-logo', 'tecrmi-logo-small',
      'tecalliance-logo-small', 'teccom-logo-small', 'tecdoc-logo-small', 'tecfleet-logo-small', 'bugs-sm',
      'no-data-sm', 'no-result-sm', 'text-field-sm', 'upgrade-sm',
      // shell logos
      'teccom-portal-logo'
    ]
    logoList.forEach(logo => {
      // TecAlliance Logo
      this._matIconRegistry.addSvgIcon(
        logo,
        this._domSanitizer.bypassSecurityTrustResourceUrl(`${logoPath}${logo}.svg`)
      );
    });
  }
}
