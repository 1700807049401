import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class CookieStorageService {

  constructor(private _cookieService: CookieService) { }

  get(key: string): any {
    return this._cookieService.get(key) ?? null;
  }

  set(key: string, data: any): void {
    this._cookieService.set(key, data, this.getExpirationDate(new Date()), '/', this.getCurrentDomain());
  }

  private getExpirationDate(date: Date): Date {
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }

  private getCurrentDomain(): string {
    const separate = window.location.hostname.split('.');
    separate.shift();
    return separate.join('.');
  }
}