import { Routes } from '@angular/router';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { UserPermissionGuard } from '../core/guards/user-permission.guard';
import { OktaSessionGuard } from '../core/guards/okta-session.guard';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'public', pathMatch: 'full' },
  { path: 'callback', component: OktaCallbackComponent },
  {
    path: 'public',
    loadChildren: () => import('../modules/public/public.module').then(m => m.PublicModule),
    canActivate: []
  },
  {
    path: 'customer',
    loadChildren: () => import('../modules/customer/customer.module').then(m => m.CustomerModule),
    canActivate: []
  },
  {
    path: '',
    loadChildren: () => import('../modules/protected/protected.module').then(m => m.ProtectedModule),
    canActivate: [OktaAuthGuard, OktaSessionGuard, UserPermissionGuard]
  },
];
