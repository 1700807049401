import { Injectable } from '@angular/core';
import { Microfrontend } from '../models/types/microfrontend.types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, catchError, of, take, combineLatest } from 'rxjs';
import { CUSTOMER_REMOTES_ROUTES } from 'src/app/routes/customer.routes';
import { PROTECTED_REMOTES_ROUTES } from 'src/app/routes/protected.routes';
import { PUBLIC_REMOTES_ROUTES } from 'src/app/routes/public.routes';
import { uniqBy } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class CacheManagerService {
  constructor(private _httpClient: HttpClient) { }

  cacheRemoteEntries(): Observable<Microfrontend[]> {
    // Fetch all remote modules for the current browser session
    const requests: Observable<any>[] = [...CUSTOMER_REMOTES_ROUTES, ...PROTECTED_REMOTES_ROUTES, ...PUBLIC_REMOTES_ROUTES].map((m: Microfrontend) => {
      return this.fetchRemoteModule(m).pipe(take(1));
    });

    return combineLatest(requests);
  }

  refreshRemoteEntry(remoteName: string) {
    // Fetch latest version module for the current browser session
    const m = [...CUSTOMER_REMOTES_ROUTES, ...PROTECTED_REMOTES_ROUTES, ...PUBLIC_REMOTES_ROUTES].find((m: Microfrontend) => m.remoteName === remoteName);
    if(m) {
      this.fetchRemoteModule(m).pipe(take(1)).subscribe();
    }
  }

  private fetchRemoteModule(m: Microfrontend): Observable<Microfrontend> {
    const headers = m.remoteUri.includes('localhost') ? {} : new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this._httpClient.head(`${m.remoteUri}${m.remoteFile}`, {
      observe: 'response',
      headers: headers
    }).pipe(
      map(response => {
        if (response.status === 200) {
          m.isAccessible = true
        } else {
          m.isAccessible = false
        }
        return m;
      }),
      catchError((error) => {
        console.warn(`${m.displayName} module is not accessible`, error);
        m.isAccessible = false;
        return of(m);
      })
    );
  }
}