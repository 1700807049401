import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, forkJoin } from 'rxjs';
import { concatMap, catchError, map, toArray, take } from 'rxjs/operators';
import { merge } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { RemoteTranslations } from '../models/remote-translations.model';
import { TranslationScopeType } from 'shared-states';

export class TranslationsLoader implements TranslateLoader {
  private _translationUrl = `${environment.translationServiceUrl}/api/translation/all`;

  constructor(private http: HttpClient, private remoteRoutes: RemoteTranslations[]) { }
  /**
   * Gets translations for a specified language from both remote modules and the shell.
   * Merges remote module and shell translations for a comprehensive set of translations.
   * @param language - The language for which translations are requested.
   * @returns An observable emitting the merged translations.
   */
  getTranslation(language: string): Observable<any> {
    // Get translation for Shell, Errors and remote modules that have a scope defined
    // via api call
    const scopes = [
      TranslationScopeType.shell,
      ...this.remoteRoutes.filter((route: RemoteTranslations) => route.scope)
        .map((route: RemoteTranslations) => route.scope!)
    ];
    const shellTranslationObs = this.http.get<any>(this._translationUrl, {
      params: {
        "scopes": scopes,
        "language": language
      }
    }).pipe(catchError(() => of([])));

    // Get remote translation via json assets
    const remoteTranslationsObs = from(this.remoteRoutes.filter((route: RemoteTranslations) => route.uri)).pipe(
      concatMap((route: RemoteTranslations) => {
        const remoteUri = `${route.uri}${language}.json`;
        return this.http.get<any>(remoteUri)
          .pipe(take(1), map((t: any) => this.addTranslationsPrefix(route.keyPrefix, t)), catchError(() => of({})));
      }),
      toArray<any>()
    );

    return forkJoin([shellTranslationObs, remoteTranslationsObs]).pipe(
      take(1),
      map(([shell, remote]) => {
        return merge(shell, ...remote);
      }))
  }

  private addTranslationsPrefix(prefix: string, t: any): any {
    return { [prefix]: t };
  }
}