import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TecComUser } from 'shared-states';

@Injectable({ providedIn: 'root' })
export class TecComUserService {
  constructor(private _httpClient: HttpClient) {
  }

  getTecComUser(): Observable<TecComUser> {
    return this._httpClient.get<TecComUser>(`${environment.userServiceUrl}/api/user`);
  }
}