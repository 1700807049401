import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Observable, from, map, of, switchMap, take, tap } from 'rxjs';
import { TecComNotificationReceived, TecComNotificationType, User } from 'shared-states';
import { environment } from 'src/environments/environment';
import { CacheManagerService } from './remote-preload.service';
import { VersionNotificationModel } from '../models/version-notification.model';
import { Microfrontend } from '../models/types/microfrontend.types';
import { ShellState, ShellStateModel } from '../states';
import { PROTECTED_REMOTES_ROUTES } from 'src/app/routes/protected.routes';
import { MatDialog } from '@angular/material/dialog';
import { TscDialogModel } from 'src/app/shared/components/tsc-dialog/models/tsc-dialog.model';
import { TscDialogComponent } from 'src/app/shared/components/tsc-dialog/tsc-dialog.component';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _hubConnection!: HubConnection;
  private readonly _notificationHubUrl = `${environment.notificationServiceUrl}/notification-hub`;
  private readonly _notificationControllerUrl = `${environment.notificationServiceUrl}/api/notification`;

  constructor(private _store: Store, 
    private _httpClient: HttpClient, 
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, 
    private _cacheManagerService: CacheManagerService,
    private _dialog: MatDialog) {

  }

  public init(): Observable<TecComNotificationReceived[]> {
    if (environment.notificationServiceUrl) {
      this._hubConnection = new HubConnectionBuilder()
        .withUrl(this._notificationHubUrl, {
          accessTokenFactory: () => this._oktaAuth.getAccessToken()!
        })
        .withAutomaticReconnect()
        .configureLogging(environment.production ? LogLevel.None : LogLevel.Error)
        .build();

      return this.startConnection();
    }
    return of([]);
  }

  public markAsRead(notification: TecComNotificationReceived, sendEvent: boolean): Observable<boolean> {
    return this._httpClient.patch(`${this._notificationControllerUrl}/${notification.id}/read`, '')
      .pipe(map(() => {
        this._store.dispatch(new User.NotificationReadSuccessAction(notification, sendEvent));
        return true;
      }));
  }

  public markAllAsRead(): Observable<boolean> {
    return this._httpClient.patch(`${this._notificationControllerUrl}/all`, '')
      .pipe(map(() => {
        this._store.dispatch(new User.NotificationReadAllSuccessAction());
        return true;
      }));
  }

  public delete(notification: TecComNotificationReceived): Observable<boolean> {
    return this._httpClient.delete(`${this._notificationControllerUrl}/${notification.id}`)
      .pipe(map(() => {
        this._store.dispatch(new User.NotificationDeleteSuccessAction(notification));
        return true;
      }));
  }

  public deleteAll(): Observable<boolean> {
    return this._httpClient.delete(`${this._notificationControllerUrl}/all`)
      .pipe(map(() => {
        this._store.dispatch(new User.NotificationDeleteAllSuccessAction());
        return true;
      }));
  }

  private registerEventHandlers() {
    this._hubConnection.on('receivemessage', (notification: TecComNotificationReceived) => {
      notification.content = JSON.parse(notification.content);
      if (notification.type === TecComNotificationType.NewVersionReleased) {
        const content = notification.content as VersionNotificationModel;
        this._cacheManagerService.refreshRemoteEntry(content?.moduleName);
        this.activateUpdate(content?.moduleName);
      } else {
        this._store.dispatch(new User.NotificationReceivedSuccessAction(notification));
      }
    });
    this._hubConnection.onclose(async () => {
      this.startConnection();
    });
  }

  private startConnection(): Observable<TecComNotificationReceived[]> {
    return from(this._hubConnection
      .start()
      .then(() => {
        this.registerEventHandlers();
      })
      .catch()
    ).pipe(switchMap(() => this._hubConnection.connectionId ? this.getAllNotifications() : of([])));
  };

  private getAllNotifications(): Observable<TecComNotificationReceived[]> {
    return this._httpClient.get<TecComNotificationReceived[]>(`${this._notificationControllerUrl}/all/`)
      .pipe(tap((notifications: TecComNotificationReceived[]) => {
        notifications.forEach((n: TecComNotificationReceived) => n.content = JSON.parse(n.content));
        this._store.dispatch(new User.NotificationInitSuccessAction(notifications));
      }));
  }

  private activateUpdate(remoteName: string) {
    // Show confirm when active module has an update
    const m = PROTECTED_REMOTES_ROUTES.find((m: Microfrontend) => m.remoteName === remoteName);
    const shellState = this._store.selectSnapshot<ShellStateModel>(ShellState);
    if (m?.module === shellState.activeModule || remoteName === 'shell') {
      this.openDialog();
    }
  }

  openDialog(): void {
    const dialogData: TscDialogModel = {
      icon: 'info-circle',
      title: 'Shell.Common.VersionUpdate.Title',
      subtitle: 'Shell.Common.VersionUpdate.Subtitle',
      description: 'Shell.Common.VersionUpdate.Description',
      okLabel: 'Shell.Common.Refresh',
      dismissLabel: 'Shell.Common.Dismiss',
      dismissable: true
    };

    const dialogRef = this._dialog.open(TscDialogComponent, {
      width: '600px',
      data: dialogData
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
      if (result) {
        document.location.reload();
      }
    });
  }
}
